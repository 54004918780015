import "./Header.scss";

// import { Link, NavLink } from "react-router-dom";

function Header() {
  return <nav className="navbar"></nav>;
}

export default Header;

// <section className="navbar__content">
// <Link to="/">
//   <img src={logo} alt="InStock logo" className="logo" />
// </Link>
// <div className="nav-links">
//   <NavLink to="/warehouses" className="nav-button">
//     Warehouses
//   </NavLink>

//   <NavLink to="/inventory" className="nav-button">
//     Inventory
//   </NavLink>
// </div>
// </section>
